import { cloneDeep } from 'lodash';
import vuex from '../store';
import Utils from './Utils';

const host = `${process.env.VUE_APP_HTTP_SERVER}`;

export async function createUser(companyId, user) {
    const userData = {
        email: user.email,
        password: user.password,
        phone_number: user.phone_number,
        first_name: user.first_name,
        last_name: user.last_name
    }

    try {
        const url = host + "v3/companies/" + companyId + "/users/register/";
        const options = {
            method: "POST",
            body: JSON.stringify(userData),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
            }
        }
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }else if (!res.ok) {
            return false;
        }
        return await res.json();
    } catch (e) {
        console.log(e);
        console.warn("Add user failed");
        return false;
    }
}

export async function editUserRole(companyId, user) {
    const userData = {
        "role": user.role
    }

    try {
        const url = host + "v3/companies/" + companyId + "/users/" + user.id + "/role/";
        const options = {
            method: "PATCH",
            body: JSON.stringify(userData),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
            }
        }
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (res.ok) {
            vuex.commit('Users/editRole', user);
            return true;
        } else {
            return false;
        }

    } catch (e) {
        console.warn("Edit user role failed");
        return false;
    }
}

export async function getUserProfile(companyId, userId) {
    try {
        const url = host + "v3/companies/" + companyId + "/users/profile?user=" + userId;
        const options = {
            method: "GET",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
            }
        }
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        try {
            const resJson = await res.json();
            if (resJson) {
                return resJson;
            }
        } catch (e) {
            console.warn("Property has no JSON Content or other error");
        }
        return null;

    } catch (e) {
        console.warn("Fetch user profile failed");
        return null;
    }
}

export async function updateUserProfile(companyId, userId, user) {
    let user_profile = cloneDeep(user);
    if (user.hasOwnProperty('phone_number')) {
        user_profile.phonenumber = user_profile.phone_number;
        delete user_profile.phone_number;
    }
    const url = host + "v3/companies/" + companyId + "/users/" + userId + "/update_profile/";
    const options = {
        method: "PATCH",
        body: JSON.stringify(user_profile),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (res.ok) {
            vuex.commit('Users/editProfile', {...user_profile, email: user.email});
            return true;
        } else {
            return false;
        }

    } catch (e) {
        return false;
    }
}

export async function getProjectProperty(companyId, projectId, propertyName) {
    const url = host + "v3/companies/" + companyId + "/projects/" + projectId + "/properties/?name=" + propertyName;
    const options = {
        headers: {
            'Accept': 'application/json, text/plain',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    const res = await fetch(url, options);

    try {
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }

        if (!res.ok) {
            console.error("An error occurred");
            return null;
        }

        try {
            const resJson = await res.json();
            if (resJson) {
                return resJson["results"][0]["value"];
            }
        } catch (e) {
            console.warn("Property has no JSON Content or other error");
        }
        return null;
    } catch (e) {
        console.error("An error occurred in getProjectProperty");
        return null;
    }
}

export async function setProjectProperty(companyId, projectId, name, value) {
    const url = host + "v3/companies/" + companyId + "/projects/" + projectId + "/properties/?name=" + name;
    const options = {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
            console.error("Could not set project property");
        }

        const resJson = await res.json();

        if (!res.ok) {
            return null;
        }

        return {status: res.status, response: resJson};
    }catch (e) {
        return null;
    }
}

export async function setCompanyProperty(companyId, name, value) {
    const url = host + "v3/companies/" + companyId + "/properties/?name=" + name;
    const options = {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
            console.error("Could not set company property");
        }
    
        const resJson = await res.json();
    
        if (!res.ok) {
            console.error("An error occurred in changing company property");
            return null;
        }
    
        return {status: res.status, response: resJson};
    }catch (e) {
        return null;
    }
}

async function assignUserAsCA(companyId, projectId, userId) {

    try {
        const url = host + "v3/companies/" + companyId + "/projects/" + projectId + "/users/" + userId + "/assign_user/";
        const options = {
            method: "POST",
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
            }
        }
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        const newUserJson = await res.json();
        return newUserJson.id 
            ? { "success": true } 
            : { "success": false, "name": newUserJson };
    } catch (e) {
        return { "success": false, "name": "Adding user failed" };
    }
}

export async function unassignUserFromProject(companyId, projectId, userId) {
    const url = host + "v3/companies/" + companyId + "/projects/" + projectId + "/users/" + userId + "/unassign_user/";
    const options = {
        method: "DELETE",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }

        if (!res.ok) {
            console.error("An error occurred");
            return null;
        }
        return res.ok;

    } catch (e) {
        console.warn(e);
        return false;
    }

}

export async function assignUserToProject(companyId, projectId, userId) {
    const url = host + "v3/companies/" + companyId + "/projects/" + projectId + "/users/" + userId + "/assign_user/";
    const options = {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (!res.ok) {
            console.error("An error occurred");
            return null;
        }
        return res.ok;

    } catch (e) {
        console.warn(e);
        return false;
    }

}

export async function forgotPassword(username) {
    const url = host + "v3/login/forgot_password/?email=" + encodeURIComponent(username);
    const options = {
        method: "GET",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        }
    }

    const res = await fetch(url, options);

    if (res.status === 403) {
        Utils.handleNotAllowedUser();
        console.error("User not found");
    } else if (res.status === 400) {
        console.error("Bad request");
        Utils.displayError(i18n.t('user.resetPassword.error'));
    }

    const resJson = await res.json();
    if (!res.ok) {
        console.error("An error occurred in userLogin");
        return null;
    }

    return resJson;
}

export async function changePassword(username, companyId, token, password) {
    const url = host + "v3/companies/" + companyId + "/users/change_password/";
    const payload = {
        "email": username,
        "token": token,
        "password": password,
        "password_confirm": password
    }
    const options = {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        }
    }

    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
            console.error("User not found");
        }
        return res;

    }catch (e) {
        return {ok: false};
    }
}

export async function setDeviceProperty(deviceId, propertyName ,value) {
    const apiKey = await JSON.parse(localStorage.getItem('user')).apikey;
    const url = host + "v3/devices/" + deviceId + "/properties/?name=" + propertyName;
    const options = {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': apiKey
        }
    }
    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
            console.error("User not found");
        }
        return (res.status === 201 || res.status === 200);
    }catch (e) {
        return false;
    }
    
  }

  export async function setUserProperty(name, value) {
    const url = host + "v3/properties/?name=" + name;
    const options = {
        method: "POST",
        body: JSON.stringify(value),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }

    try {
        const res = await fetch(url, options);
        if (res.status === 403) {
            Utils.handleNotAllowedUser();
        }
        if (!res.ok) {
            return false;
        }
        return true;
    }catch (e) {
        return false;
    }
}

export async function deleteUser(company_id, user_email) {
    const url = host + `v3/companies/${company_id}/users/admin_remove/`;
    const options = {
        method: "DELETE",
        body: JSON.stringify({email: user_email}),
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'x-api-key': await JSON.parse(localStorage.getItem('user')).apikey
        }
    }
    const res = await fetch(url, options);
    if (res.status === 403) {
        Utils.handleNotAllowedUser();
    }else {
        vuex.commit('Users/deleteUser', user_email);
    }

    return true;
}