var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{staticClass:"center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-card',{attrs:{"flat":""}},[_c('v-card',{staticClass:"pa-4 wrapper"},[_c('div',{staticClass:"device-data",class:_vm.lang === 'en' ? 'mr-3' : 'ml-3'},[_c('ul',{staticClass:"pa-0"},_vm._l((_vm.dataToShowTondo),function(value,name){return _c('li',{key:name},[(name !== 'Providers')?_c('span',{staticClass:"item-title"},[_vm._v(_vm._s(name))]):_vm._e(),(name === 'Hardware id and mac' || name === 'Config')?_c('div',_vm._l((value),function(subPropValue,subPropName){return _c('p',{key:subPropName},[_vm._v(" "+_vm._s(_vm.$t(("" + subPropName)))+" : "+_vm._s(subPropValue)+" ")])}),0):(name === 'Providers' && _vm.providersProp)?[_c('v-layout',{staticClass:"mt-2",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs3":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('SIM Provider')))])]),_c('v-flex',{attrs:{"d-flex":"","xs9":""}},[_c('v-flex',{staticClass:"px-2",attrs:{"xs6":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('ICCID')))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('IMSI')))])])],1)],1),_vm._l((Object.entries(_vm.providersProp)),function(ref,provider_index){
var physical_sim = ref[0];
var sim_data = ref[1];
return _c('v-layout',{key:physical_sim,class:provider_index === Object.entries(_vm.providersProp).length - 1 ? 'mb-2' : '',attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-divider',{attrs:{"light":""}})],1),_c('v-flex',{attrs:{"xs3":""}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(physical_sim))])]),_c('v-flex',{staticStyle:{"word-break":"break-word"},attrs:{"xs9":""}},[_vm._l((Object.entries(sim_data.iccid)),function(ref){
var iccid = ref[0];
var imsi_data = ref[1];
return [_c('v-layout',{key:iccid,attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"px-2",attrs:{"xs6":""}},[_c('span',[_vm._v(_vm._s(iccid))])]),_c('v-flex',{attrs:{"xs6":""}},[_c('span',[_vm._v(_vm._s(imsi_data.join(', ')))])])],1)]})],2)],1)})]:(name === 'disconnect_alert')?_c('div',{staticStyle:{"position":"relative"}},[_c('p',{key:"disconnect_alert"},[_vm._v(" "+_vm._s(_vm.$t(("" + _vm.disconnectAlert)))+" "),(_vm.disconnectAlert !== null && _vm.disconnectAlert !== 2)?_c('v-btn',{style:(_vm.language !== 'he'
                    ? 'position: absolute;top: -25px;left: 120px;border-radius: 20px;'
                    : 'position: absolute;top: -25px;left: 40px;border-radius: 20px;'),attrs:{"color":"success"},on:{"click":_vm.setDisconnect}},[_vm._v(_vm._s(_vm.$t('Override'))+" (2)")]):_vm._e()],1)]):(name === 'allegro_flag')?_c('div',{staticStyle:{"position":"relative"}},[_c('p',{key:"allegro_flag"},[_vm._v(" "+_vm._s(_vm.$t(("" + (_vm.dataToShowTondo.allegro_flag))))+" "),(_vm.dataToShowTondo.allegro_flag === 'false' && _vm.dataToShowTondo.allegro_flag !== 'N/A' && !_vm.readonly_user)?_c('v-btn',{style:(_vm.language !== 'he'
                    ? 'position: absolute;top: -25px;left: 120px;border-radius: 20px;'
                    : 'position: absolute;top: -25px;left: 40px;border-radius: 20px;'),attrs:{"color":"success"},on:{"click":function($event){return _vm.changeAllegroFlag('true')}}},[_vm._v(_vm._s(_vm.$t('True')))]):_vm._e(),(_vm.dataToShowTondo.allegro_flag === 'true' && _vm.dataToShowTondo.allegro_flag !== 'N/A' && !_vm.readonly_user)?_c('v-btn',{style:(_vm.language !== 'he'
                    ? 'position: absolute;top: -25px;left: 120px;border-radius: 20px;'
                    : 'position: absolute;top: -25px;left: 40px;border-radius: 20px;'),attrs:{"color":"grey"},on:{"click":function($event){return _vm.changeAllegroFlag('false')}}},[_vm._v(_vm._s(_vm.$t('False')))]):_vm._e()],1)]):(name === 'Celular status')?_c('div',[_c('p',{key:"cellular"},[_vm._v(" "+_vm._s(_vm.$t(("" + (_vm.cellularStatus !== null ? _vm.cellularStatus : 'N/A'))))+" ")])]):_c('span',{staticClass:"item-value"},[_vm._v(_vm._s(value ? value : _vm.$t('N/A')))])],2)}),0)]),_c('div',{staticClass:"actions"},[_c('v-select',{attrs:{"items":Object.keys(_vm.actionItemsList),"label":_vm.$t('Modem Data and Commands'),"disabled":_vm.readonly_user,"attach":"","menu-props":"bottom, offsetY"},model:{value:(_vm.selectedAction),callback:function ($$v) {_vm.selectedAction=$$v},expression:"selectedAction"}}),_c('v-btn',{attrs:{"disabled":!_vm.isActionSelected || _vm.readonly_user,"text":"","color":"primary"},on:{"click":_vm.runSelectedAction}},[_vm._v(_vm._s(_vm.$t('Run')))]),_c('div',{staticClass:"mqtt-data"},[_c('h3',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(_vm._s(_vm.$t('Data from the stream'))+":")]),_vm._l((_vm.statusFromMQTT),function(item,index){return _c('div',{key:item + index,staticClass:"mqtt-data-item"},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }