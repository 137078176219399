












































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Route } from '@/modules/Types';
import routes from '@/router/routes';
import MapView from '@/pages/mapView/MapView.vue';
import { MapSettings } from '@/store';
import { Global, Projects, User, Reports, Props } from '@/store';
import API, { Types } from '@/modules/API';
import cloneDeep from 'lodash/cloneDeep';

@Component({})
export default class Sidebar extends Vue {
  @Global.State('readonly_user') readonly_user;
  @Global.State('isMobile') isMobile;
  @Global.State('isLoading') isFirstLoading;
  @Global.State('lang') lang;
  @Global.State('theme') theme;
  @Global.State('isSidebarOpen') isSidebarOpen;
  @Global.State('editFilterMode') editFilterMode;
  @Global.Mutation('setIsSidebarOpen') setIsSidebarOpen;
  @Global.Mutation('setContentStyles') setContentStyles;
  @Global.Mutation('setEditFilterMode') setEditFilterMode;
  @Global.Mutation('setAllProjectsTimezone') setAllProjectsTimezone;
  @Reports.Getter('commissionedWaterMeter') commissionedWaterMeter;
  @Reports.Getter('commissionedMotionSensor') commissionedMotionSensor;
  @Reports.Getter('commissionedFloodSensor') commissionedFloodSensor;
  @User.State('isAuth') isAuth;
  @User.State('role') role;
  @User.State('project') project;
  @User.State('username') username;
  @User.State('isManager') isManager;
  @User.State('properties') userProperties;
  @User.Mutation('selectProject') selectProject;
  @Projects.State('list') projects;
  @MapSettings.State('userMapFilters') userMapFilters;
  @MapSettings.State('userClusters') userClusters;
  @MapSettings.State('isLoading') isMapLoading;

  disabledNotManager = [
    'FilterView',
    'GroupsList',
    'Reports',
    'ProjectAlerts',
    'Sensors',
    'ManagerOptions',
    'AlertManagment',
    'CabinetManagement',
    'EventManagment',
    'Settings'
  ];

  mobileDisabled = [
    'FilterView',
    'Settings',
    'Help',
    'ManagerOptions',
    'Sensors'
  ];

  mounted() {
    this.setContentStyles();
  }

  @Watch('userMapFilters', { deep: true })
  get navigations() {
    if (!this.project) return;
    let filtered_routes = cloneDeep(routes);
    if (this.project.id === 'allProjects'){
      return filtered_routes.filter((route) => this.disabledNotManager.indexOf(route.name) === -1);
    }

    filtered_routes = filtered_routes.filter((route) => route.menuTitle !== 'General Settings');

    // Update sensors list according to exist sensor devices
    const sensors = filtered_routes.find((route) => route.menuTitle === 'Sensors');
    const sensors_index = filtered_routes.findIndex((route) => route.menuTitle === 'Sensors');
    this.setFilteredSensors(sensors);
    if (!sensors.children.length) {
      filtered_routes.splice(sensors_index, 1);
    }else {
      filtered_routes.splice(sensors_index, 1, sensors);
    }

    // Update custom filter views list
    const filter_views = filtered_routes.find((route) => route.name === 'FilterView');
    const filters_index = filtered_routes.findIndex((route) => route.name === 'FilterView');
    this.addCustomFilters(filter_views);
    if (this.readonly_user && filter_views && filter_views.children && filter_views.children.length) {
      const create_filter_route_index = filter_views.children.findIndex((route) => route.createFilter);
      if (create_filter_route_index !== -1) {
        filter_views.children.splice(create_filter_route_index, 1);
      }
    }
    filtered_routes.splice(filters_index, 1, filter_views);

    if (this.isMobile) {
      return filtered_routes.filter((route) => !this.mobileDisabled.includes(route.name));
    }

    return this.isManager
      ? filtered_routes
      : filtered_routes.filter((route) => route.name !== 'ManagerOptions');
  }

  setFilteredSensors(sensors){
    sensors.children = this.commissionedWaterMeter.length > 0
      ? sensors.children
      : sensors.children.filter((route) => route.name !== 'WaterMeter');
    sensors.children = this.commissionedMotionSensor.length > 0
      ? sensors.children
      : sensors.children.filter((route) => route.name !== 'MotionSensor');
    sensors.children = this.commissionedFloodSensor.length > 0
      ? sensors.children
      : sensors.children.filter((route) => route.name !== 'FloodSensor');
  }

  editFilter(path) {
    this.goToFilter(path);
    this.setEditFilterMode(true);
  }

  goToFilter(path) {
    if (!this.$route.path.includes(path)) {
      this.$router.push(path);
      const isCreateFilter = path.includes('/filter/create');
      this.setEditFilterMode(isCreateFilter);
    }
  }

  get projectsList() {
    if (this.projects.length > 1) {
      return this.projects.concat([{ name: 'All Projects' }]);
    }
    return this.projects;
  }

  addCustomFilters(filter_views) {
    // @ts-ignore
    const { routes } = this.$router.options;
    const clusterRoutes = [];
    const routeData = routes.find((route) => route.path === '/');

    if (routeData) {
      this.userMapFilters.forEach((route) => {
        route.component = MapView;
        route.parent = '/';
      });
      // routeData.children = this.userMapFilters.filter((filter) => !filter.cluster);
      Array.from(this.userClusters.keys()).forEach((cluster) => {
        const clusterRoute = {
          name: cluster,
          menuTitle: cluster,
          component: MapView,
          parent: '/',
          children: this.userClusters.get(cluster)
        };
        // this.userClusters.get(cluster).forEach((element) => routeData.children = [...routeData.children, element]);
        clusterRoutes.push(clusterRoute);
      });
      // this.$router.addRoutes([routeData]);
      const custom_filters = [...this.userMapFilters, ...clusterRoutes];
      custom_filters.sort((a, b) => a.menuTitle.localeCompare(b.menuTitle));
      
      filter_views.children[0].createFilter = true;
      filter_views.children[0]['groups'] = ['all'];
      filter_views.children[0]['parent'] = '/';
      filter_views.children = [filter_views.children[0], ...custom_filters];
    }
    this.$forceUpdate();
  }

  @Watch('isSidebarOpen')
  onToggle() {
    if (!this.isSidebarOpen) {
      const items = document.getElementsByClassName('v-list__group__header v-list__group__header--active')[0];
      if (items) {
        items.classList.remove('v-list__group__header--active');
      }
      const head = document.getElementsByClassName('v-list__group v-list__group--active')[0];
      if (head) {
        head.classList.remove('v-list__group--active');
      }
      const r = document.getElementsByClassName('v-list__group__items v-list__group__items--no-action');
      for (const element of r) {
        element['style'].display = 'none';
      }
    }
    this.setContentStyles();
  }

  getLink(route: Route): any {
    route.realPath = route.path;
    if (!route.menuTitle) return false;
    if (route.path.indexOf(':') !== -1) {
      if (
        this.$route.matched.map((r) => r.path === route.path).indexOf(true) !==
        -1
      ) {
        route.realPath = this.$route.path;
      } else return false;
    }

    return route;
  }

  openSide(r) {
    if (!this.isSidebarOpen) {
      const head = document.getElementById(r);
      head.click();
      this.setIsSidebarOpen(true);
    }
  }

  isFilterActive(route: Route) {
    return this.$route.path === route.path;
  }

  isActive(route: Route) {
    if (route.menuTitle === 'Full View') {
      return true;
    }
    return route.children
      ? route.children
          .map((subRoute) => this.isActive(subRoute))
          .indexOf(true) !== -1
      : this.getLink(route).realPath === this.$route.path;
  }

  async setProject(selectedProject) {
    if (selectedProject.id){
      this.selectProject(selectedProject);
    }else {
      this.selectProject(this.unifiyAllProjects());
      await this.setTimezone(); // set timezone of the first project in list
    }
    
    this.$nextTick(() => {
      this.$router.push('/fullViewReport');
    });
  }

  async setTimezone(){
    let projects_timezone = await this.fetchTimezoneProperty();
    projects_timezone = projects_timezone 
      ? projects_timezone.hasOwnProperty('timezone')
        ? projects_timezone.timezone
        : projects_timezone
      : 'Asia/Tel_Aviv';

    this.setAllProjectsTimezone(projects_timezone);
  }

  async fetchTimezoneProperty(){
    const property = await API.get(Types.COMPANIES, `${this.projectsList[0].company}/${Types.PROJECTS}/${this.projectsList[0].id}/properties`, {name: 'project.timezone'});
    return property && property.results && property.results[0] && property.results[0].value ? property.results[0].value : '';
  }

  unifiyAllProjects() {
    const idsAllProjects = [];
    const namesAllProjects = [];
    const companiesAllProjects = [];
    this.projects.map((item) => {
      idsAllProjects.indexOf(item.id) === -1 ? idsAllProjects.push(item.id) : console.log('This item already exists');
      namesAllProjects.indexOf(item.name) === -1 ? namesAllProjects.push(item.name) : console.log('This item already exists');
      companiesAllProjects.indexOf(item.company) === -1 ? companiesAllProjects.push(item.company) : console.log('This item already exists');
    });
    const allProjectsData = {
      id: 'allProjects',
      name: 'All Projects',
      description: `The following projects are shown: ${namesAllProjects.toString()}`,
      created: Date().toString(),
      company: 'allProjects',
      modified: null,
      idsAllProjects,
      namesAllProjects,
      companiesAllProjects,
      projects: this.projects
    };
    return allProjectsData;
  }
}
