































import Component from 'vue-class-component';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Prop } from 'vue-property-decorator';
import { Props, Global } from '@/store';
import API, { Types } from '@/modules/API';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import StreamChart from '../StreamChart.vue';
import moment from 'moment';

@Component({
  components: {
    StreamChart
  }
})
export default class EnergyInfo extends Mixins<TabsInfoMixin>(TabsInfoMixin) {

  @Prop() deviceStreams;
  
  @Global.State('lang') lang;

  pickedPeriod = '1weeks';
  selectedStream = 'power_consumption';
  streamsLatestValue = { power_consumption: ['Power Consumption', '', false, 'watts'], voltage: ['Voltage', '', false, 'volt'], current: ['Current', '', false, 'amps'], power_factor: ['Power Factor', '', false, '']};
  show_buttons = false;
  loading = true;

  filterEnum = {
    '1days': 'Last 24 Hours',
    '2days': 'Last 48 Hours',
    '1weeks': 'Last Week',
    '1months': 'Last Month',
    '3months': 'Last 3 Month'
  };

  periods_range = new Map();
  power_consumption_history = [];

  async created() {
    this.loading = true;
    this.setPeriodsRange();
    await this.setStreamsLatestValues();
    Object.values(this.streamsLatestValue).forEach((stream_data) => {
      if (stream_data[1]) stream_data[2] = true;
    });

    const stream = Object.entries(this.streamsLatestValue).find(([stream_name, stream_data]) => stream_data[2] === true);
    if (stream){
      this.selectedStream = this.streamsLatestValue.power_consumption[2] ? 'power_consumption' : stream[0];
      this.show_buttons = true;
    }else {
      this.selectedStream = '';
      this.show_buttons = false;
    }
    this.loading = false;
  }

  setPeriodsRange(){
    const today_timestamp = moment().unix() * 1000;
    this.periods_range.set('1days', {from: moment(today_timestamp).subtract(1, 'days').valueOf(), to: today_timestamp});
    this.periods_range.set('2days', {from: moment(today_timestamp).subtract(2, 'days').valueOf(), to: today_timestamp});
    this.periods_range.set('1weeks', {from: moment(today_timestamp).subtract(7, 'days').valueOf(), to: today_timestamp});
    this.periods_range.set('1months', {from: moment(today_timestamp).subtract(1, 'months').valueOf(), to: today_timestamp});
    this.periods_range.set('3months', {from: moment(today_timestamp).subtract(3, 'months').valueOf(), to: today_timestamp});
  }

  async setStreamsLatestValues(){
    await this.fetchPowerConsumptionHistory();

    const voltage_latest = PropUtils.getDeviceStream('voltage', this.deviceStreams);
    const current_latest = PropUtils.getDeviceStream('current', this.deviceStreams);
    const power_factor_latest = PropUtils.getDeviceStream('power_factor', this.deviceStreams);

    if (this.power_consumption_history.length)
      this.streamsLatestValue.power_consumption[1] = this.getPowerConsumptionTitle(this.power_consumption_history[0]);
    if (voltage_latest && (voltage_latest.value || voltage_latest.value === 0))
      this.streamsLatestValue.voltage[1] = await this.getStreamLatestValue(this.device.id, 'voltage');
    if (current_latest && (current_latest.value || current_latest.value === 0))
      this.streamsLatestValue.current[1] = await this.getStreamLatestValue(this.device.id, 'current');
    if (power_factor_latest && (power_factor_latest.value || power_factor_latest.value === 0))
      this.streamsLatestValue.power_factor[1] = await this.getStreamLatestValue(this.device.id, 'power_factor');
  }

  async fetchPowerConsumptionHistory(){
    try {
      const start = this.periods_range.get(this.pickedPeriod).from;
      const end = this.periods_range.get(this.pickedPeriod).to;

      const url = `get_power_and_connected`;
      const body = {
        company_id: this.project.company,
        project_id: this.project.id,
        device_id: this.device.id,
        start_time: start,
        end_time: end
      };
      const response = await API.dashboardAPI(url, 'POST', {}, body);
      const formatted = response.result.map((data) => [data.timestamp, data.value]);
      this.power_consumption_history = formatted.reverse();
    
    } catch (e){
      this.power_consumption_history = [];
    }
  }

  async getStreamLatestValue(deviceId, streamName) {
    const response = await API.get(Types.DEVICES, `${deviceId}/streams/${deviceId}.${streamName}/latest/`);
    return !response.timestamp && !response.lastvalue ? '' : !response.timestamp ? `${response.lastvalue}` : `${response.lastvalue}, ${this.convertTimestampToString(response.timestamp)}`;
  }

  getPowerConsumptionTitle(stream){
    return !stream[0] && !stream[1] ? '' : !stream[0] ? `${stream[1]}` : `${stream[1]}, ${this.convertTimestampToString(stream[0])}`;
  }

  convertTimestampToString(timestamp){
    const date = new Date(timestamp);
    return Utils.getDateString(date);
  }

  changeStreamChart(streamName){
    this.selectedStream = streamName;
  }

  get selectedStreamName(){
    return this.selectedStream 
      ? this.streamsLatestValue[this.selectedStream][0]
      : '';
  }

  get selectedStreamUnit(){
    return this.selectedStream 
      ? this.streamsLatestValue[this.selectedStream][3]
      : '';
  }

  get periods_list(){
    return [
      { text: this.$t('Last 24 hours'), value: '1days' },
      { text: this.$t('Last 48 Hours'), value: '2days' },
      { text: this.$t('Last Week'), value: '1weeks' },
      { text: this.$t('Last Month'), value: '1months' },
      { text: this.$t('Last 3 Months'), value: '3months' }
    ];
  }
}
