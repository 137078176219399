



































































































































import Component from 'vue-class-component';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import TabsInfoMixin from './TabsInfoMixin';
import { Global, DeviceStreams } from '@/store';
import MQTT from '@/modules/MQTT';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import API, { Types } from '@/modules/API';
import OverviewTab from '../information/tondoTabs/OverviewTab.vue';
import ControlTab from '../information/tondoTabs/ControlSliderTab.vue';
import EnergyTab from '../information/tondoTabs/EnergyInfo.vue';
import AdvancedTab from '../information/tondoTabs/AdvancedTab.vue';
import DeviceDataTab from '../information/tondoTabs/DeviceDataTab.vue';
import TemperatureTab from '../information/tondoTabs/TemperatureTab.vue';
import CurveTab from '../information/tondoTabs/CurveTab.vue';
import mapConfig from '@/pages/mapView/components/map/map.config';

@Component({
  components: {
    OverviewTab,
    ControlTab,
    EnergyTab,
    AdvancedTab,
    DeviceDataTab,
    TemperatureTab,
    CurveTab
  }
})

export default class TondoInfoTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Prop() isContainer;
  @Prop() containers;
  @Prop() container_id;

  @Global.State('lang') lang;
  @Global.State('mqtt_version_by_class') mqtt_version_by_class;
  @DeviceStreams.Action('fetchAllStreamsForSingleDevice') fetchAllStreamsForSingleDevice;
  @DeviceStreams.Mutation('addDeviceStream') addDeviceStream
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @DeviceStreams.Mutation('setDeviceStreams') setDeviceStreams;

  mqtt = MQTT.instance;
  streamVersion = null;
  streamPower = null;
  streamPowerObject = {};
  group_ids = [];
  powerMsgSent = false;
  showTemp = false;
  deviceTemp = '';
  statusFromMQTT: string[] = [];
  isDimLevelUpdated = true;
  isDeviceTypeRelay = false;
  metaDeviceData = {};
  loadingTabs = true;
  deviceFixture = null;
  propsList = null;
  percentToDaliTable = {};
  daliToPercentTable = {};
  converted = false;
  tab = 'tab-overview';
  container_devices = [];
  mqtt_subscribe_topic = '';
  mqtt_version = 3;

  async mounted() {
    if (this.container_id){
      this.updateContainerDevices();
    }
    
    await this.deviceChanged();
  }

  setTabItem(tabIndex) {
    this.tab = tabIndex;
  }

  initMqtt() {
    this.mqtt.init();
    this.listenToMqtt();
  }

  updateMetaDeviceData(meta_device){
    this.metaDeviceData = {...meta_device};
    this.device['meta.device'] = {...meta_device};
  }

  @Watch('container_id')
  updateContainerDevices(){
    if (this.container_id){
      this.container_devices = [];
      this.container_devices = this.containers[this.container_id];
    }
  }

  @Watch('device')
  async deviceChanged() {
    this.setDeviceStreams([]);
    if (this.mqtt_subscribe_topic){
      this.mqtt.unlisten(this.mqtt_subscribe_topic);
    }
    this.mqtt_subscribe_topic = '';
    try {
      this.powerMsgSent = false;
      this.active = 'tab-overview';
      this.tab = 'tab-overview';
      this.loadingTabs = true;
      this.clearData();
      await this.fetchOverviewStreams();
      await this.fetchProperties();
      [this.converted, this.percentToDaliTable, this.daliToPercentTable] = await Utils.convertDimmerValues(this.device.id);
      this.initMqtt();
      this.loadingTabs = false;
      
    }
    catch (error) {
      console.log('ERROR while watch device:', error);
      this.loadingTabs = false;
    }
  }

  @Watch('tab')
  async tabChanged() {
    this.loadingTabs = true
    switch(this.tab) {
      case 'tab-overview':
        await this.fetchOverviewStreams();
        break;
      case 'tab-energy':
        await this.fetchEnergyStreams();
        break;
      case 'tab-advanced':
        await this.fetchAdvancedStreams();
        break;
    }
    this.loadingTabs = false
  }

  get deviceIcon(){
    if (this.device.sys___active === 0) {
      return mapConfig.icons.regularElectricalFault;
    }else if (this.device.sys___active === -1) {
      return mapConfig.icons.regularInMaintenance;
    }else if (this.device.sys___active === -3) {
      return mapConfig.icons.regularInInstallation;
    }else if (this.device.sys___active === -9) {
      return mapConfig.icons.regularInCare;
    }
    else {
      return this.device.power > 0 ? this.device.sys___connected === 0 ? mapConfig.icons.yellowRedLine : mapConfig.icons.regularOn :
        this.device.sys___connected === 0 ? mapConfig.icons.greyRedLine : mapConfig.icons.regularOff;
    } 
  }

  listenToMqtt() {
    this.mqtt.listen(this.mqtt_subscribe_topic,
      async (msg, match) => {
        const mqtt_msg_data = this.mqtt.getMqttMsgData(msg, match, this.mqtt_version);

        if (mqtt_msg_data.device_id === this.device.id){
          try {
            if (mqtt_msg_data.stream_name === 'command' && mqtt_msg_data.message !== 'AT+m=AT+QTEMP') {
              this.checkCommandMsg(mqtt_msg_data.message);
            }else if (mqtt_msg_data.stream_name === 'power') {
              this.checkPowerMsg(mqtt_msg_data.message, mqtt_msg_data.message_type);
            }
            
            if (mqtt_msg_data.message_type === 'evt' &&
                ((typeof mqtt_msg_data.message === 'string' && 
                  !mqtt_msg_data.message.includes('+QTEMP') &&
                  !mqtt_msg_data.message.includes('+CME ERROR')) || 
                  mqtt_msg_data.format === 'json'
                )
              ) {
                this.setEventsToAdvancedTab(mqtt_msg_data.stream_name, mqtt_msg_data.message);
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    );
  }

  async checkCommandMsg(message){
    if (typeof message === 'string' && (message.includes('+QTEMP') || message.includes('+CME ERROR'))){
      const date = await this.getTempTime();
      const temp = message.includes('+QTEMP') ? this.parseTemp(message) : 'FAULT'; 
      this.deviceTemp = `Device Temperature: ${temp} c at ${date}`;
      this.showTemp = true;
    }
  }

  checkPowerMsg(message, message_type){
    if (message_type === 'evt'){
      this.isDimLevelUpdated = true;
      this.streamPower = message;
      this.powerMsgSent = false;
    }else {
      this.powerMsgSent = true;
    }
  }

  setEventsToAdvancedTab(streamName, message){
    console.log(
      '%c' + 'ADVANCED TAB - MQTT MESSAGE:' + message,
      'background: #222; color: #bada15'
    );
    const final_message = `${streamName}: ${message}`;
    this.statusFromMQTT.push(final_message);
  }

  mqttGetMsg(format, message) { 
    return format === 'minimal' ? Number(message) : Number(message['content']);
  }

  parseTemp(message) {
    const startIndex = message.indexOf(',');
    const endIndex = message.slice(startIndex + 1).indexOf(',');
    return message.slice(startIndex + 1, startIndex + endIndex + 1);
  }

  async getTempTime() {
    const response = await API.get(
      Types.DEVICES,
      `${this.device.id}/streams/${this.device.id}.command/latest/`);
    const date = new Date(response['timestamp']);
    return Utils.getDateString(date);
  }

  async fetchOverviewStreams() {
    this.streamPowerObject = await Utils.fetchStreamLatestValue(this.device.id, 'power');
    this.streamPower = Number(this.streamPowerObject['value']);
    this.streamVersion = await Utils.fetchStreamLatestValue(this.device.id, 'version_nrf52840');
    this.streamVersion = this.streamVersion && this.streamVersion.value || 'N/A';
  }

  async fetchEnergyStreams() {
    await this.setDeviceStreams([]);
    await this.addSingleStream('voltage')
    await this.addSingleStream('current')
    await this.addSingleStream('power_factor')
    console.log("Device streams are ", this.deviceStreams)
  }

  async fetchAdvancedStreams() {
    await this.setDeviceStreams([])
    await this.addSingleStream('cellid')
    await this.addSingleStream('rssi')
    await this.addSingleStream('sys___connected')
    await this.addSingleStream('sys___active')
  }

  async fetchStreams() {
    await this.setDeviceStreams([]);
    await this.fetchAllStreamsForSingleDevice(this.device.id);
    this.streamPowerObject = Utils.getStream('power', this.deviceStreams);
    this.streamPower = Number(this.streamPowerObject['value']);
    this.streamVersion = Utils.getStream('version_nrf52840', this.deviceStreams);
    this.streamVersion = this.streamVersion && this.streamVersion.value || 'N/A';
  }

  containerDeviceClicked(device){
    this.mqtt.unlisten(this.mqtt_subscribe_topic);
    this.$emit('deviceChanged', device);
  }

  updateDim(value = false) {
    this.isDimLevelUpdated = value;
  }

  async addSingleStream(streamName) {
    await this.addDeviceStream({name: streamName, value: (await Utils.fetchStreamLatestValue(this.device.id, streamName))['value'], id: `${this.device.id}.${streamName}`})
  }

  async getStreamChangeTime(stream) {
    if (!stream) 
      return;
    const response = await API.get(
      Types.DEVICES,
      `${this.device.id}/streams/${stream.id}/latest/`);
    if (!response['timestamp']) {
      return 'N/A';
    }
    const date = new Date(response['timestamp']);
    return Utils.getDateString(date);
  }

  async fetchProperties() {
    const propsList = await API.get(Types.DEVICES, this.device.id + '/properties');
    this.propsList = propsList;

    let metaDevice = null;

    if (propsList && Array.isArray(propsList.results)) {
      const fixtureProp = propsList.results.find((el) => el.name === 'device.fixture');
      const metaDeviceProp = propsList.results.find((el) => el.name === 'meta.device');

      this.deviceFixture = fixtureProp ? fixtureProp.value : null;
      metaDevice = metaDeviceProp ? metaDeviceProp.value : null;
      
      if (this.deviceFixture && this.deviceFixture.type === 'relay') {
        this.isDeviceTypeRelay = true;
      }
      if (metaDevice && typeof metaDevice === 'object') {
        PropUtils.reorderMetaDevice(metaDevice);
        this.metaDeviceData = metaDevice;
      }

      const device_group_ids = await API.get(Types.PROJECTS, `${this.project.id}/devices/${this.device.id}/groups`, {}, {}, 'v4');
      this.group_ids = device_group_ids || [];
      if (this.mqtt_version_by_class.has(this.device.class_name)) {
        this.mqtt_version = this.mqtt_version_by_class.get(this.device.class_name).mqtt_version;
      }
      this.mqtt_subscribe_topic = this.mqtt.getSingleDeviceSubscribe(this.device.id, this.mqtt_version);
    }
  }

  clearData() {
    this.isDeviceTypeRelay = false;
    this.metaDeviceData = {};
    this.statusFromMQTT = [];
    this.deviceTemp = '';
    this.showTemp = false;
  }

  beforeDestroy() {
    this.setDeviceStreams([]);
    this.mqtt.unlisten(this.mqtt_subscribe_topic);
  }
}
