



























































import Component from 'vue-class-component';
import TabsInfoMixin from './TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Global } from '@/store';
import Utils from '@/modules/Utils';
import PropUtils from '@/modules/PropUtils';
import { i18n } from '../../../../../main';

@Component({
  components: {
  }
})
export default class VirtualControllerInfoTabs extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @Global.State('lang') lang;

  get getGeoLocation() {
    return Utils.deviceLocation(this.device);
  }
}
