


















































import Component from 'vue-class-component';
import TabsInfoMixin from '../../devices/TabsInfoMixin';
import { Mixins } from 'vue-mixin-decorator';
import { Prop, Watch } from 'vue-property-decorator';
import { Global, DeviceStreams } from '@/store';
import moment from 'moment';
import VueApexCharts from 'vue-apexcharts';
import Utils from '@/modules/Utils';

@Component({
  components: {
    VueApexCharts
  }
})

export default class TemperatureTab extends Mixins<TabsInfoMixin>(TabsInfoMixin) {
  @DeviceStreams.Action('fetchAllStreamsForSingleDevice') fetchAllStreamsForSingleDevice;
  @DeviceStreams.Mutation('addDeviceStream') addDeviceStream
  @DeviceStreams.State('deviceStreams') deviceStreams;
  @DeviceStreams.Mutation('setDeviceStreams') setDeviceStreams;
  @Global.State('lang') lang;

  // @ts-ignore
  @Prop() device;

  filterEnum = {
    '1years': 'All',
    '1hours': 'Last Hour',
    '1days': 'Last 24 Hours',
    '2days': 'Last 48 Hours',
    '1weeks': 'Last 7 Days',
    '1months': 'Last 30 Days',
    '6months': 'Last 6 Months'
  };

  pickedPeriod = '';
  loading = true;
  noData = false;
  streamHistory = [];
  series = [{
    name: 'temperature',
    data: null
  }];

  chartOptions = {
    chart: {
        type: 'area',
        stacked: false,
        height: 350,
        zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
    },
    toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: []
        },
        export: {
          csv: {
            show: false,
            filename: 'Temperature',
            columnDelimiter: ',',
            headerCategory: 'time',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toString();
            }
          },
          svg: {
            filename: 'Temperature'
          },
          png: {
            filename: 'Temperature'
          }
        },
        autoSelected: 'zoom' 
        }
            },
            dataLabels: {
              enabled: true
            },
            stroke: {
              curve: 'smooth'
            },
            markers: {
              size: 0
            },
            title: {
              text: '',
              align: 'center',
              style: {
                fontFamily: 'Open Sans, Arial, monospace'
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
              }
            },
            yaxis: {
              labels: {
                offsetX: -5,
                formatter: (val) => {
                  return parseInt(val, 10) === val ? val : val.toFixed(1);
                }
              }
            },
            xaxis: {
              type: 'datetime',
              labels: {
                datetimeUTC: false
              }
            },
            tooltip: {
              shared: false,
              x: {
                format: 'dd/MM/yy HH:mm'
              },
              y: {
                formatter: (val) => {
                  return val.toFixed(0);
                },
                title: {
                  formatter: (seriesName) => seriesName
                }
              }
            }
          };

  mounted() {
    this.loadStreams();
    this.chartOptions.title.text = this.$t('Temperature');
    this.chartOptions.tooltip.y.title.formatter = (seriesName) => ` ${this.$t(seriesName)}: `;
  }

  filterSelect(e) {
      let period = '1days';

      switch (e) {
        case 'All':
          period = '1years';
          break;
        case 'Last Hour':
          period = '1hours';
          break;
        case 'Last 24 Hours':
          period = '1days';
          break;
        case 'Last 48 Hours':
          period = '2days';
          break;
        case 'Last 7 Days':
          period = '1weeks';
          break;
        case 'Last 30 Days':
          period = '1months';
          break;
        case 'Last 6 Months':
          period = '6months';
          break;
        default:
          period = '1days';
          break;
      }
      this.pickedPeriod = period;
      this.updateStreams();
    }

    @Watch('device')
    async loadStreams() {
        this.setDeviceStreams([]);
        await this.loadTemperature();
        this.filterSelect('Last 24 Hours');
    }

    async loadTemperature() {
      await this.addDeviceStream({name: 'modem_temperature', value: (await Utils.fetchStreamLatestValue(this.device.id, 'modem_temperature'))['value'], id: `${this.device.id}.modem_temperature`});
    }

    async updateStreams() {
        this.noData = false;
        try {
            this.loading = true;
            const stream = Utils.getStream('modem_temperature', this.deviceStreams);
            if (stream) {
                this.streamHistory = await Utils.fetchSingleStreamHistory({
                    device: this.device,
                    stream,
                    period: this.pickedPeriod
                });
                this.streamHistory.reverse();
            }

            // this.streamHistory.map((el) => {
            //     const startIndex = el[1].indexOf(',');
            //     const endIndex = el[1].slice(startIndex + 1).indexOf(',');
            //     const temp = el[1].slice(startIndex + 1, startIndex + endIndex + 1);
            //     el[1] = Number(temp);
            // });

            const updatedSeries = [{ name: 'temperature', data: this.streamHistory }];
            this.series = updatedSeries;
      
            if (this.streamHistory.length === 0) {
                this.noData = true;
            }
  
            this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
        }
    }

    getTime(time) {
      if (time) {
        return moment(time).format('L') + ' ' + moment(time).format('HH:mm:ss');
      } else {
        return '';
      }
    }
}
